import React, { useState } from "react";
import "./styles/RecentWorks.css";
import doc from "../Assests/doc.png";
import gully from "../Assests/gully.png";
import jwell from "../Assests/jwell.png";
import stars from "../Assests/stars.png";
import tw from "../Assests/logos/tw.jpg";
import dhansarthi from "../Assests/logos/dhansarthi.png";
import java from "../Assests/logos/java.svg";
import dwj from "../Assests/logos/dwj.png";
import resc from "../Assests/logos/resc.png";
import med from "../Assests/logos/med.png";
import estate from "../Assests/estate.jpg";

import ddf1 from "../Assests/logos/ddf1.svg";
import ddf2 from "../Assests/logos/ddf2.svg";
const RecentWorks = () => {
  const [act, setAct] = useState(0);
  const [dfInd, setDfInd] = useState(0);
  const [dndInd, setDndInd] = useState(0);
  const df = [ddf1, ddf2];
  const dnd = [
    {
      img: dwj,
      abs: jwell,
      exp: 1,
      comp: "Didwania Jewellers Store",
      text: "Didwania Jewellers Store is a renowned establishment offering exquisite and timeless pieces of jewelry. With a rich heritage in traditional craftsmanship, the store embraced digital transformation with the assistance of Neon Shark, a cutting-edge digital solutions provider. Neon Shark's expertise has propelled Didwania Jewelry Store into the digital realm, enhancing its online presence, customer engagement, and overall business efficiency. Together, they seamlessly blend tradition and technology and technology to elevate the jewelry shopping experience.",
    },
    {
      img: java,
      exp: 1.5,
      abs: java,
      comp: "JAVA Sports",
      text: "JAVA Sports, a leading cricket gear manufacturer, specializes in crafting high-quality equipment for cricket enthusiasts. Partnering with Neon Shark, a digital solutions provider, JAVA Sports has embraced a digital evolution.  Through this collaboration, JAVA Sports seamlessly combines traditional cricket craftsmanship with digital innovation to cater to the modern needs of cricket enthusiasts worldwide ",
    },
    {
      img: tw,
      exp: 1.6,
      abs: gully,
      comp: "T20 Gully Cricket",
      text: "T20 Gully Cricket pioneers the organization of state-level cricket tournaments, bringing the excitement of gully cricket to a broader stage. With a passion for the game, they create a platform that unites local talent and showcases the spirit of cricket at the grassroots level. T20 Gully Cricket's commitment to fostering talent and community engagement elevates the cricketing experience for players and enthusiasts alike.",
    },
    {
      img: dhansarthi,
      exp: 1,
      abs: dhansarthi,
      comp: "Dhansarthi",
      text: "Dhansarthi, a prominent loan provider, revolutionized its digital presence with the assistance of Neon Shark, a leading digital solutions provider. Neon Shark implemented cutting-edge technologies and user-friendly interfaces, enhancing Dhansarthi's online loan application and approval processes. This collaboration has not only streamlined operations but has also elevated customer experience, making Dhansarthi a more accessible and efficient financial partner.",
    },
    {
      img: resc,
      exp: 1,
      abs: estate,
      comp: "ResiGreen",
      text: "ResiGreen, a forward-thinking real estate firm, partnered with Neon Shark to redefine its digital landscape. Neon Shark's innovative solutions empowered ResiGreen to create immersive virtual property tours, streamline transaction processes, and enhance customer interactions. Through this collaboration, ResiGreen has embraced digital transformation, offering a modern and seamless real estate experience that sets them apart in the competitive market.",
    },
    {
      img: med,
      exp: 2,
      abs: doc,
      comp: "Med Life Easy",
      text: "Med Life Easy, a premier medical services provider, enlisted the expertise of Neon Shark to elevate its digital presence and service delivery. Neon Shark implemented cutting-edge technologies, optimizing Med Life Easy's platform for efficient appointment scheduling, telemedicine, and seamless access to health resources. This collaboration has enhanced the overall patient experience, making Med Life Easy a trusted and technologically advanced hub for top-notch medical services.",
    },
  ];

  const handleClick = (change) => {
    if (act === 1) {
      //console.log("hii", dfInd, change, change + dfInd);
      if (change + dfInd >= 0 && change + dfInd < df.length) {
        setDfInd(change + dfInd);
      }
    } else if (act === 0) {
      //console.log("hii", dndInd, change, change + dndInd);
      if (change + dndInd >= 0 && change + dndInd < dnd.length) {
        setDndInd(change + dndInd);
      }
    }
  };
  return (
    <div className="rw-cont" id="our-works">
      <div className="ecl2"></div>
      <div className="ecl3"></div>
      <div className="ser-head">Recent Works</div>
      <div className="rw-inner-cont">
        <div className="rw-left-cont">
          <div className="rw-left-head">Categories</div>
          <div
            className={`rw-left-opt ${act === 0 ? "rw-opt-act" : ""}`}
            onClick={() => setAct(0)}
          >
            Digital Marketing
          </div>
          <div
            className={`rw-left-opt ${act === 1 ? "rw-opt-act" : ""}`}
            onClick={() => setAct(1)}
          >
            Design & Development
          </div>
          {/* <div
            className={`rw-left-opt ${act === 2 ? "rw-opt-act" : ""}`}
            onClick={() => setAct(2)}
          >
            Portfolio Companies
          </div> */}
        </div>

        {act === 0 && (
          <div className="rw-right-cont">
            <img src={dnd[dndInd].abs} className="doc-abs" alt="doc-abs" />
            <div className="rw-right-top">
              <img src={dnd[dndInd].img} alt="medEasy" />
              <div className="rw-right-head">{dnd[dndInd].comp}</div>

              <div className="rw-right-text">{dnd[dndInd].text}</div>
            </div>
            <div className="rw-right-btm">
              <div className="rw-btm-text">
                {dnd[dndInd].exp} years+ PARTNERSHIP
              </div>
            </div>
          </div>
        )}
        {act === 1 && (
          <div className="rw-right-i">
            <img src={df[dfInd]} className="ddf" alt="ddf"></img>
          </div>
        )}
      </div>

      <div className="rw-arrows">
        <div className="rw-less rw-arr" onClick={() => handleClick(-1)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <g clipPath="url(#clip0_185_914)">
              <path
                d="M10.6667 16L18.6667 24L20.5467 22.12L14.44 16L20.5467 9.88L18.6667 8L10.6667 16Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_185_914">
                <rect
                  width="32"
                  height="32"
                  fill="white"
                  transform="matrix(0 -1 1 0 0 32)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="rw-more rw-arr" onClick={() => handleClick(+1)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <g clipPath="url(#clip0_185_918)">
              <path
                d="M21.3333 16L13.3333 8L11.4533 9.88L17.56 16L11.4533 22.12L13.3333 24L21.3333 16Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_185_918">
                <rect
                  width="32"
                  height="32"
                  fill="white"
                  transform="matrix(0 1 -1 0 32 0)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>

      <div className="rw-cta">
        <div className="hero-cta-border">
          <div className="hero-cta">Get in Touch</div>
          <img className="hero-stars" src={stars} alt="stars" />
          <div className="hero-cta-border-inner">
            <div
              className="hero-cta"
              onClick={() => {
                const section = document.querySelector("#contactus");

                if (section) {
                  window.scrollTo({
                    top: section.offsetTop,
                    behavior: "smooth",
                  });
                }
              }}
            >
              Get in Touch
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentWorks;
