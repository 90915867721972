import React from "react";
import "./styles/WeWorkWith.css";
import Marquee from "react-fast-marquee";
import med from "../Assests/logos/agc.png";
import adobe from "../Assests/logos/Bionic.png";
import amazon from "../Assests/logos/bn24.png";
import apple from "../Assests/logos/bt.png";
import google from "../Assests/logos/dwj.png";
import paytm from "../Assests/logos/java.svg";
import resigreen from "../Assests/logos/resigreen.png";
import ganak from "../Assests/logos/ganak.jpg";
import ds from "../Assests/logos/dhansarthi.png";
import medbw from "../Assests/logos/medbw.png";
import gc from "../Assests/logos/gc.png";
import Wowwiw from "../Assests/logos/Wowwie.png";
const WeWorkWith = () => {
  return (
    <div className="www-cont">
      <div className="www-head">Brands We Work With</div>
      <div className="www-moving-cont">
        <Marquee
          gradient={true}
          gradientColor={"#050505"}
          className="marquee"
          autoFill={true}
        >
          <div className="www-img-cont">
            <img src={medbw} alt="logo" className="greyImg" />
          </div>
          <div className="www-img-cont">
            <img src={ganak} alt="logo" className="greyImg" />
          </div>
          <div className="www-img-cont">
            <img src={gc} alt="logo" className="greyImg" />
          </div>
          <div className="www-img-cont">
            <img src={ds} alt="logo" className="greyImg" />
          </div>
          <div className="www-img-cont">
            <img src={med} alt="logo" className="greyImg" />
          </div>
          <div className="www-img-cont">
            <img src={adobe} alt="logo" className="greyImg" />
          </div>
          <div className="www-img-cont">
            <img src={amazon} alt="logo" className="greyImg" />
          </div>
          <div className="www-img-cont">
            <img src={apple} alt="logo" className="greyImg" />
          </div>
          <div className="www-img-cont">
            <img src={google} alt="logo" className="greyImg" />
          </div>
          <div className="www-img-cont">
            <img src={paytm} alt="logo" className="greyImg" />
          </div>
          <div className="www-img-cont">
            <img src={resigreen} alt="logo" className="greyImg" />
          </div>
          <div className="www-img-cont">
            <img src={Wowwiw} alt="logo" className="greyImg" />
          </div>
        </Marquee>
      </div>
    </div>
  );
};

export default WeWorkWith;
