import React, { useEffect } from "react";
import Hero from "../Components/Hero";
import "./styles/Home.css";
import WeWorkWith from "../Components/WeWorkWith";
import Whyus from "../Components/Whyus";
import Service from "../Components/Service";
import RecentWorks from "../Components/RecentWorks";
import Reviews from "../Components/Reviews";
import Contact from "../Components/Contact";
import FooterCont from "../Components/FooterCont";

import logo from "../Assests/logo.png";
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="home-cont">
      <div
        className="hero-si-float"
        onClick={() => window.open("https://wa.me/919798990383")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <g clipPath="url(#clip0_185_734)">
            <path
              d="M27.287 4.65026C24.292 1.65324 20.3097 0.00182855 16.0667 0C7.32341 0 0.208143 7.113 0.204643 15.8559C0.203624 18.6506 0.933841 21.3786 2.32179 23.7833L0.0714722 32L8.48013 29.7951C10.7969 31.0586 13.4054 31.7244 16.0599 31.7255H16.0666H16.0667C24.8086 31.7255 31.9249 24.6112 31.9286 15.8687C31.9301 11.6315 30.2816 7.64748 27.287 4.65026ZM16.0667 29.0473H16.0615C13.6955 29.0461 11.3752 28.4108 9.35135 27.2099L8.86971 26.9244L3.87984 28.2327L5.21207 23.3694L4.89826 22.8708C3.57844 20.7722 2.88169 18.3471 2.88268 15.8569C2.88558 8.5901 8.79952 2.67822 16.0717 2.67822C19.5933 2.67968 22.9031 4.05235 25.3924 6.54338C27.8816 9.03438 29.2514 12.3458 29.2503 15.8676C29.2473 23.1347 23.3331 29.0473 16.0667 29.0473Z"
              fill="#E0E0E0"
            />
            <path
              d="M0.799622 31.0884L2.94715 23.247C1.62226 20.9521 0.92553 18.3485 0.926366 15.682C0.92984 7.33866 7.72015 0.550781 16.0633 0.550781C20.1127 0.55261 23.9132 2.1285 26.7707 4.98869C29.6288 7.84885 31.2019 11.6506 31.2002 15.6938C31.1968 24.0374 24.406 30.8259 16.0639 30.8259C16.0643 30.8259 16.0633 30.8259 16.0639 30.8259H16.0572C13.5239 30.8251 11.0348 30.1892 8.82402 28.9843L0.799622 31.0884Z"
              fill="url(#paint0_linear_185_734)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.147 9.12412C11.8536 8.47167 11.5446 8.45861 11.2654 8.44719C11.037 8.43739 10.7756 8.438 10.5146 8.438C10.2534 8.438 9.82889 8.53616 9.46992 8.9282C9.11074 9.32024 8.09851 10.268 8.09851 12.1955C8.09851 14.1232 9.50257 15.9859 9.69828 16.2475C9.8942 16.5087 12.4087 20.5907 16.3908 22.1611C19.7004 23.4662 20.3739 23.2066 21.0922 23.1413C21.8106 23.076 23.4101 22.1937 23.7367 21.2788C24.0632 20.3641 24.0632 19.5801 23.9652 19.4162C23.8673 19.2529 23.6061 19.155 23.2142 18.9591C22.8224 18.7632 20.8963 17.8152 20.5371 17.6846C20.1779 17.554 19.9167 17.4887 19.6555 17.8809C19.3943 18.2728 18.6439 19.155 18.4153 19.4162C18.1867 19.678 17.9582 19.7107 17.5663 19.5148C17.1745 19.3182 15.9127 18.905 14.4158 17.5703C13.2511 16.5319 12.4648 15.2495 12.2362 14.8573C12.0076 14.4654 12.2117 14.2532 12.4082 14.0579C12.5841 13.8824 12.8001 13.6005 12.996 13.3718C13.1915 13.143 13.2568 12.9797 13.3874 12.7185C13.518 12.4569 13.4527 12.2281 13.3547 12.0322C13.2568 11.8363 12.4954 9.8988 12.147 9.12412Z"
              fill="white"
              fillOpacity="0.75"
            />
            <path
              d="M27.1574 4.59982C24.1969 1.63721 20.2602 0.00478436 16.066 0.00292969C7.42302 0.00292969 0.389437 7.03427 0.385963 15.6768C0.384944 18.4394 1.10678 21.1361 2.47879 23.5132L0.254333 31.6356L8.56647 29.456C10.8566 30.7049 13.4352 31.3631 16.0592 31.3641H16.0659H16.066C24.7075 31.3641 31.7421 24.3316 31.7458 15.6894C31.7472 11.5009 30.1176 7.56264 27.1574 4.59982ZM16.066 28.7168H16.0609C13.7219 28.7156 11.4283 28.0877 9.42769 26.9006L8.95156 26.6183L4.01897 27.9116L5.33589 23.1041L5.02569 22.6112C3.72102 20.5368 3.03226 18.1394 3.03328 15.6778C3.03613 8.49447 8.88218 2.65046 16.0709 2.65046C19.5521 2.65189 22.8239 4.00881 25.2846 6.47123C27.7452 8.93366 29.0993 12.2071 29.0983 15.6885C29.0952 22.872 23.2489 28.7168 16.066 28.7168Z"
              fill="white"
              fillOpacity="0.75"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_185_734"
              x1="15.9999"
              y1="31.0884"
              x2="15.9999"
              y2="0.550832"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#20B038" />
              <stop offset="1" stopColor="#60D66A" />
            </linearGradient>
            <clipPath id="clip0_185_734">
              <rect
                width="31.8592"
                height="32"
                fill="white"
                transform="translate(0.0704346)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>

      <div className="hero-nav">
        <div className="hero-logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="hero-cta-border hovstop hovstop1 ">
          <div
            className="nav-btn-cta"
            onClick={() => {
              const section = document.querySelector("#contactus");

              if (section) {
                window.scrollTo({
                  top: section.offsetTop,
                  behavior: "smooth",
                });
              }
            }}
          >
            Get in Touch
          </div>
        </div>
        <div className="hero-nav-right">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              const section = document.querySelector("#our-works");

              if (section) {
                window.scrollTo({
                  top: section.offsetTop,
                  behavior: "smooth",
                });
              }
            }}
          >
            Our work
          </div>

          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              const section = document.querySelector("#why-us");

              if (section) {
                window.scrollTo({
                  top: section.offsetTop,
                  behavior: "smooth",
                });
              }
            }}
          >
            Why Us
          </div>

          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              const section = document.querySelector("#services");

              if (section) {
                window.scrollTo({
                  top: section.offsetTop,
                  behavior: "smooth",
                });
              }
            }}
          >
            Services
          </div>

          <div className="hero-cta-border hovstop">
            <div
              className="nav-btn-cta"
              onClick={() => {
                const section = document.querySelector("#contactus");

                if (section) {
                  window.scrollTo({
                    top: section.offsetTop,
                    behavior: "smooth",
                  });
                }
              }}
            >
              Get in Touch
            </div>
          </div>
        </div>
      </div>
      <Hero />
      <WeWorkWith />
      <Whyus />
      <Service />
      <RecentWorks />
      <Reviews />
      <Contact />
      <FooterCont />
    </div>
  );
};

export default Home;
