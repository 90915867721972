import React from "react";
import "./styles/Hero.css";
import heroImg from "../Assests/heroImg.png";
import stars from "../Assests/stars.png";

const Hero = () => {
  return (
    <div className="hero-cont">
      <div className="hero-pn"></div>

      <div className="hero-main-cont">
        <div className="hero-main-left">
          <div className="hero-main-head">
            <span>Digital Solution</span>
            <br />
            for every business need
          </div>
          <div className="hero-cta-border">
            <div className="hero-cta">Get in Touch</div>
            <img className="hero-stars" src={stars} alt="stars" />
            <div className="hero-cta-border-inner">
              <div className="hero-cta" onClick={() => {
              const section = document.querySelector("#contactus");

              if (section) {
                window.scrollTo({
                  top: section.offsetTop,
                  behavior: "smooth",
                });
              }
            }}>
                Get in Touch
              </div>
            </div>
          </div>
        </div>
        <div className="ecl-img">
          <div className="eclipse5"></div>
          <img src={heroImg} alt="heroImg" className="hero-abs-img" />
        </div>
      </div>
      <div className="hero-socials">
        <div
          className="hero-si"
          onClick={() => window.open("https://wa.me/919798990383")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <g clipPath="url(#clip0_185_734)">
              <path
                d="M27.287 4.65026C24.292 1.65324 20.3097 0.00182855 16.0667 0C7.32341 0 0.208143 7.113 0.204643 15.8559C0.203624 18.6506 0.933841 21.3786 2.32179 23.7833L0.0714722 32L8.48013 29.7951C10.7969 31.0586 13.4054 31.7244 16.0599 31.7255H16.0666H16.0667C24.8086 31.7255 31.9249 24.6112 31.9286 15.8687C31.9301 11.6315 30.2816 7.64748 27.287 4.65026ZM16.0667 29.0473H16.0615C13.6955 29.0461 11.3752 28.4108 9.35135 27.2099L8.86971 26.9244L3.87984 28.2327L5.21207 23.3694L4.89826 22.8708C3.57844 20.7722 2.88169 18.3471 2.88268 15.8569C2.88558 8.5901 8.79952 2.67822 16.0717 2.67822C19.5933 2.67968 22.9031 4.05235 25.3924 6.54338C27.8816 9.03438 29.2514 12.3458 29.2503 15.8676C29.2473 23.1347 23.3331 29.0473 16.0667 29.0473Z"
                fill="#E0E0E0"
              />
              <path
                d="M0.799622 31.0884L2.94715 23.247C1.62226 20.9521 0.92553 18.3485 0.926366 15.682C0.92984 7.33866 7.72015 0.550781 16.0633 0.550781C20.1127 0.55261 23.9132 2.1285 26.7707 4.98869C29.6288 7.84885 31.2019 11.6506 31.2002 15.6938C31.1968 24.0374 24.406 30.8259 16.0639 30.8259C16.0643 30.8259 16.0633 30.8259 16.0639 30.8259H16.0572C13.5239 30.8251 11.0348 30.1892 8.82402 28.9843L0.799622 31.0884Z"
                fill="url(#paint0_linear_185_734)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.147 9.12412C11.8536 8.47167 11.5446 8.45861 11.2654 8.44719C11.037 8.43739 10.7756 8.438 10.5146 8.438C10.2534 8.438 9.82889 8.53616 9.46992 8.9282C9.11074 9.32024 8.09851 10.268 8.09851 12.1955C8.09851 14.1232 9.50257 15.9859 9.69828 16.2475C9.8942 16.5087 12.4087 20.5907 16.3908 22.1611C19.7004 23.4662 20.3739 23.2066 21.0922 23.1413C21.8106 23.076 23.4101 22.1937 23.7367 21.2788C24.0632 20.3641 24.0632 19.5801 23.9652 19.4162C23.8673 19.2529 23.6061 19.155 23.2142 18.9591C22.8224 18.7632 20.8963 17.8152 20.5371 17.6846C20.1779 17.554 19.9167 17.4887 19.6555 17.8809C19.3943 18.2728 18.6439 19.155 18.4153 19.4162C18.1867 19.678 17.9582 19.7107 17.5663 19.5148C17.1745 19.3182 15.9127 18.905 14.4158 17.5703C13.2511 16.5319 12.4648 15.2495 12.2362 14.8573C12.0076 14.4654 12.2117 14.2532 12.4082 14.0579C12.5841 13.8824 12.8001 13.6005 12.996 13.3718C13.1915 13.143 13.2568 12.9797 13.3874 12.7185C13.518 12.4569 13.4527 12.2281 13.3547 12.0322C13.2568 11.8363 12.4954 9.8988 12.147 9.12412Z"
                fill="white"
                fillOpacity="0.75"
              />
              <path
                d="M27.1574 4.59982C24.1969 1.63721 20.2602 0.00478436 16.066 0.00292969C7.42302 0.00292969 0.389437 7.03427 0.385963 15.6768C0.384944 18.4394 1.10678 21.1361 2.47879 23.5132L0.254333 31.6356L8.56647 29.456C10.8566 30.7049 13.4352 31.3631 16.0592 31.3641H16.0659H16.066C24.7075 31.3641 31.7421 24.3316 31.7458 15.6894C31.7472 11.5009 30.1176 7.56264 27.1574 4.59982ZM16.066 28.7168H16.0609C13.7219 28.7156 11.4283 28.0877 9.42769 26.9006L8.95156 26.6183L4.01897 27.9116L5.33589 23.1041L5.02569 22.6112C3.72102 20.5368 3.03226 18.1394 3.03328 15.6778C3.03613 8.49447 8.88218 2.65046 16.0709 2.65046C19.5521 2.65189 22.8239 4.00881 25.2846 6.47123C27.7452 8.93366 29.0993 12.2071 29.0983 15.6885C29.0952 22.872 23.2489 28.7168 16.066 28.7168Z"
                fill="white"
                fillOpacity="0.75"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_185_734"
                x1="15.9999"
                y1="31.0884"
                x2="15.9999"
                y2="0.550832"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#20B038" />
                <stop offset="1" stopColor="#60D66A" />
              </linearGradient>
              <clipPath id="clip0_185_734">
                <rect
                  width="31.8592"
                  height="32"
                  fill="white"
                  transform="translate(0.0704346)"
                />
              </clipPath>
            </defs>
          </svg>
          <span>WhatsApp</span>
        </div>
        <div
          className="hero-si"
          onClick={() => window.open("https://instagram.com/neonshark.co.in")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <g clipPath="url(#clip0_185_741)">
              <path
                d="M22 0H10C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10L0 22C0 24.6522 1.05357 27.1957 2.92893 29.0711C4.8043 30.9464 7.34784 32 10 32H22C24.6522 32 27.1957 30.9464 29.0711 29.0711C30.9464 27.1957 32 24.6522 32 22V10C32 7.34784 30.9464 4.8043 29.0711 2.92893C27.1957 1.05357 24.6522 0 22 0ZM29 22C29 25.86 25.86 29 22 29H10C6.14 29 3 25.86 3 22V10C3 6.14 6.14 3 10 3H22C25.86 3 29 6.14 29 10V22Z"
                fill="url(#paint0_linear_185_741)"
              />
              <path
                d="M16 8C13.8783 8 11.8434 8.84285 10.3431 10.3431C8.84285 11.8434 8 13.8783 8 16C8 18.1217 8.84285 20.1566 10.3431 21.6569C11.8434 23.1571 13.8783 24 16 24C18.1217 24 20.1566 23.1571 21.6569 21.6569C23.1571 20.1566 24 18.1217 24 16C24 13.8783 23.1571 11.8434 21.6569 10.3431C20.1566 8.84285 18.1217 8 16 8ZM16 21C14.6744 20.9984 13.4036 20.4711 12.4662 19.5338C11.5289 18.5964 11.0016 17.3256 11 16C11 13.242 13.244 11 16 11C18.756 11 21 13.242 21 16C21 18.756 18.756 21 16 21Z"
                fill="url(#paint1_linear_185_741)"
              />
              <path
                d="M24.6 8.46598C25.1887 8.46598 25.666 7.98872 25.666 7.39998C25.666 6.81125 25.1887 6.33398 24.6 6.33398C24.0113 6.33398 23.534 6.81125 23.534 7.39998C23.534 7.98872 24.0113 8.46598 24.6 8.46598Z"
                fill="url(#paint2_linear_185_741)"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_185_741"
                x1="2.928"
                y1="29.072"
                x2="29.072"
                y2="2.928"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FFC107" />
                <stop offset="0.507" stopColor="#F44336" />
                <stop offset="0.99" stopColor="#9C27B0" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_185_741"
                x1="10.344"
                y1="21.656"
                x2="21.656"
                y2="10.344"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FFC107" />
                <stop offset="0.507" stopColor="#F44336" />
                <stop offset="0.99" stopColor="#9C27B0" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_185_741"
                x1="23.846"
                y1="8.15398"
                x2="25.354"
                y2="6.64598"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FFC107" />
                <stop offset="0.507" stopColor="#F44336" />
                <stop offset="0.99" stopColor="#9C27B0" />
              </linearGradient>
              <clipPath id="clip0_185_741">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Instagram</span>
        </div>
        <div
          className="hero-si"
          onClick={() => window.open("mailto:info@neonshark.co.in")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
          >
            <g clipPath="url(#clip0_185_747)">
              <path d="M4.5 4H28.5V28H4.5V4Z" fill="#ECEFF1" />
              <path
                d="M16.5 18.524L28.5 28V9.29199L16.5 18.524Z"
                fill="#CFD8DC"
              />
              <path
                d="M29.5 4H28.5L16.5 13.476L4.5 4H3.5C2.70435 4 1.94129 4.31607 1.37868 4.87868C0.81607 5.44129 0.5 6.20435 0.5 7L0.5 25C0.5 25.7956 0.81607 26.5587 1.37868 27.1213C1.94129 27.6839 2.70435 28 3.5 28H4.5V9.292L16.5 18.522L28.5 9.29V28H29.5C30.2956 28 31.0587 27.6839 31.6213 27.1213C32.1839 26.5587 32.5 25.7956 32.5 25V7C32.5 6.20435 32.1839 5.44129 31.6213 4.87868C31.0587 4.31607 30.2956 4 29.5 4Z"
                fill="#F44336"
              />
            </g>
            <defs>
              <clipPath id="clip0_185_747">
                <rect
                  width="32"
                  height="32"
                  fill="white"
                  transform="translate(0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          <span>Gmail</span>
        </div>

        <div
          className="hero-si"
          onClick={() =>
            window.open(
              "https://www.facebook.com/profile.php?id=100085080909131&mibextid=ZbWKwL"
            )
          }
        >
          {/* <div className="hero-si" onClick={()=>window.open("")}> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
          >
            <g clipPath="url(#clip0_185_753)">
              <path
                d="M32.5 16C32.5 7.16344 25.3366 0 16.5 0C7.66344 0 0.5 7.16344 0.5 16C0.5 23.9861 6.35097 30.6053 14 31.8056V20.625H9.9375V16H14V12.475C14 8.46499 16.3887 6.25 20.0434 6.25C21.794 6.25 23.625 6.5625 23.625 6.5625V10.5H21.6074C19.6198 10.5 19 11.7333 19 12.9987V16H23.4375L22.7281 20.625H19V31.8056C26.649 30.6053 32.5 23.9861 32.5 16Z"
                fill="#1877F2"
              />
              <path
                d="M22.7281 20.625L23.4375 16H19V12.9987C19 11.7333 19.6198 10.5 21.6074 10.5H23.625V6.5625C23.625 6.5625 21.794 6.25 20.0434 6.25C16.3887 6.25 14 8.46499 14 12.475V16H9.9375V20.625H14V31.8056C15.6566 32.0648 17.3434 32.0648 19 31.8056V20.625H22.7281Z"
                fill="white"
                fillOpacity="0.75"
              />
            </g>
            <defs>
              <clipPath id="clip0_185_753">
                <rect
                  width="32"
                  height="32"
                  fill="white"
                  transform="translate(0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          <span>Facebook</span>
        </div>
      </div>
    </div>
  );
};

export default Hero;
