import React from "react";
import "./styles/Service.css";

import ser1 from "../Assests/ser1.svg";
import ser2 from "../Assests/ser2.svg";
import ser3 from "../Assests/ser3.svg";
import ser4 from "../Assests/ser4.svg";
import ser5 from "../Assests/ser5.svg";
import ser6 from "../Assests/ser6.svg";
import ser7 from "../Assests/ser7.svg";
import ser8 from "../Assests/ser8.svg";
import ser9 from "../Assests/ser9.svg";
import ser10 from "../Assests/ser10.svg";
import ser11 from "../Assests/ser11.svg";
import ser12 from "../Assests/ser12.svg";
import ser13 from "../Assests/ser13.svg";
import ser14 from "../Assests/ser14.svg";
import ser15 from "../Assests/ser15.svg";
import ser16 from "../Assests/ser16.svg";
const Service = () => {
  return (
    <div className="ser-cont" id="services">
      <div className="ser-head">Services</div>
      <div className="ser-card-out-cont">
        <div className="ser-card-cont">
          <div className="ser-card">
            <img src={ser1} alt="ser1" />
            <div className="ser-card-head">SEO</div>
            <div className="ser-card-det">
              Boost your online presence and rankings on search engines,
              ensuring your business is easily discoverable.
            </div>
          </div>
          <div className="ser-card">
            <img src={ser2} alt="ser1" />
            <div className="ser-card-head">Online Advertising</div>
            <div className="ser-card-det">
              Drive targeted traffic and increase brand visibility through
              effective online advertising campaigns
            </div>
          </div>
          <div className="ser-card">
            <img src={ser3} alt="ser1" />
            <div className="ser-card-head">Strategic Planning</div>
            <div className="ser-card-det">
              Craft a customized digital marketing strategy tailored to your
              business goals and target audience
            </div>
          </div>
          <div className="ser-card">
            <img src={ser4} alt="ser1" />
            <div className="ser-card-head">Social Media Management</div>
            <div className="ser-card-det">
              Engage and grow your audience on various social platforms with
              strategic content & campaigns.
            </div>
          </div>
        </div>
        <div className="ser-card-cont">
          <div className="ser-card">
            <img src={ser5} alt="ser1" />
            <div className="ser-card-head">Content Marketing</div>
            <div className="ser-card-det">
              Develop & distribute valuable content to attract, inform, & engage
              your audience
            </div>
          </div>
          <div className="ser-card">
            <img src={ser6} alt="ser1" />
            <div className="ser-card-head">Email Marketing</div>
            <div className="ser-card-det">
              Nurture leads & retain customers through targeted email campaigns
              that deliver personalized content
            </div>
          </div>
          <div className="ser-card">
            <img src={ser7} alt="ser1" />
            <div className="ser-card-head">Analytics & Reporting</div>
            <div className="ser-card-det">
              Monitor & analyze campaign performance, providing actionable
              insights for strategic optimization
            </div>
          </div>
          <div className="ser-card">
            <img src={ser8} alt="ser1" />
            <div className="ser-card-head">Web Design and Development</div>
            <div className="ser-card-det">
              Create visually appealing & user friendly websites that align with
              your brand identity & enhance the UX
            </div>
          </div>
        </div>
        <div className="ser-card-cont">
          <div className="ser-card">
            <img src={ser9} alt="ser1" />
            <div className="ser-card-head">App Development</div>
            <div className="ser-card-det">
              Create any app both supported for Android and iOS versions,
              including complete design to development
            </div>
          </div>
          <div className="ser-card">
            <img src={ser10} alt="ser1" />
            <div className="ser-card-head">Innovative Technologies</div>
            <div className="ser-card-det">
              Stay competitive online by adopting the latest digital trends and
              technologies.
            </div>
          </div>
          <div className="ser-card">
            <img src={ser11} alt="ser1" />
            <div className="ser-card-head">Google Ads</div>
            <div className="ser-card-det">
              Elevate your brand online with our Google Ads expertise for
              targeted and effective campaigns
            </div>
          </div>
          <div className="ser-card">
            <img src={ser12} alt="ser1" />
            <div className="ser-card-head">Complete Business Solution</div>
            <div className="ser-card-det">
              Get rewarded with the royalty of end to end business solution with
              our highly advanced Analytics tools
            </div>
          </div>
        </div>
        <div className="ser-card-cont">
          <div className="ser-card">
            <img src={ser13} alt="ser1" />
            <div className="ser-card-head">Video production</div>
            <div className="ser-card-det">
              Elevate your brand with captivating video production and
              compelling visual storytelling
            </div>
          </div>
          <div className="ser-card">
            <img src={ser14} alt="ser1" />
            <div className="ser-card-head">2D/3D Animations</div>
            <div className="ser-card-det">
              Bring your ideas to life with our dynamic 2D and 3D animation
              services.
            </div>
          </div>
          <div className="ser-card">
            <img src={ser15} alt="ser1" />
            <div className="ser-card-head">Meta Ads</div>
            <div className="ser-card-det">
              Unlock the power of engagement with our Meta Ads service – where
              creativity meets impact.
            </div>
          </div>
          <div className="ser-card">
            <img src={ser16} alt="ser1" />
            <div className="ser-card-head">Data Analytics</div>
            <div className="ser-card-det">
              Data-driven success: Transforming insights into action with our
              Analytics service.
            </div>
          </div>
        </div>
      </div>
      <div
        className="ser-enq-now"
        onClick={() => {
          const section = document.querySelector("#contactus");

          if (section) {
            window.scrollTo({
              top: section.offsetTop,
              behavior: "smooth",
            });
          }
        }}
      >
        Enquire Now!
      </div>
    </div>
  );
};

export default Service;
