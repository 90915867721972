import React, { useState } from "react";
import "./styles/Contact.css";
import contact from "../Assests/contact.svg";
import emailjs from "emailjs-com";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [sent, setSent] = useState(false);

  return (
    <div className="contact-cont" id="contactus">
      <div className="ser-head">Contact Us</div>
      <div className="ct-inner-cont">
        <div className="ct-left">
          <img src={contact} alt="contact" />
        </div>
        <div className="ct-right">
          {sent && (
            <>
              <div className="ct-thank">Thank You !!</div>
              <div className="ct-thank-text">
                Form Submitted! We will Get back to you soon.
              </div>
            </>
          )}
          {!sent && (
            <>
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="text"
                placeholder="Mobile Number"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />
              <div
                className="ct-cta"
                onClick={() => {
                  // console.log("hii");
                  emailjs
                    .send(
                      "service_xrft3nj",
                      "template_yfg6was",
                      {
                        to_name: "Shivam",
                        from_name: "Neon Shark",
                        message: `Contacted you :- Name: ${name} , Email: ${email} , Number: ${number}`,
                      },
                      "W2RgwqUjOAhgzwfUY"
                    )
                    .then((response) => {
                      console.log("Email sent successfully:", response);
                      setSent(true);
                    })
                    .catch((error) => {
                      alert("Something Went Wrong Please Try Again!!!");
                      console.log("Failed to send email:", error);
                      setSent(false);
                    });
                }}
              >
                Submit
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;
