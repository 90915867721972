import React from "react";
import "./styles/Whyus.css";
import wu1 from "../Assests/wu1.svg";
import wu2 from "../Assests/wu2.svg";
import wu3 from "../Assests/wu3.svg";
import wu4 from "../Assests/wu4.svg";
import wu5 from "../Assests/wu5.gif";
import Marquee from "react-fast-marquee";
const Whyus = () => {
  return (
    <div className="wu-cont" id="why-us">
      <div className="wu-head-cont">
        <div className="wu-abs">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1072"
            height="119"
            viewBox="0 0 1072 119"
            fill="none"
          >
            <path
              d="M1 66.5C25.5 98.5 108 62.5 122.5 55.5C137 48.5 229 31.4999 281 76.5C333 121.5 366 154 451 142.5C536 131 685.824 56.8573 749 35C854.5 -1.5 1072 -28 1054.5 61.5C1035.9 156.638 804.254 -22.1501 755 84.5C694.5 215.5 1287 177.5 1160.5 29"
              stroke="#EAEAEA"
              strokeOpacity="0.15"
              strokeWidth="1.25"
              strokeDasharray="2.5 2.5"
            />
          </svg>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="72"
          height="72"
          viewBox="0 0 72 72"
          fill="none"
        >
          <rect width="72" height="72" rx="36" fill="#111111" />
          <ellipse
            cx="18.2334"
            cy="36.0632"
            rx="5.5"
            ry="8.89963"
            transform="rotate(-27.492 18.2334 36.0632)"
            fill="#2D2D2D"
          />
          <ellipse
            cx="45.0084"
            cy="21.1571"
            rx="5.5"
            ry="7.78019"
            transform="rotate(-27.492 45.0084 21.1571)"
            fill="#2D2D2D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.2115 53.4206C35.4987 55.6657 52.9194 45.5116 60.195 28.9838C63.5973 34.2801 64.9934 40.8678 63.5812 47.5117C60.7384 60.8863 47.5916 69.4239 34.217 66.5811C26.5455 64.9505 20.4654 59.9298 17.2115 53.4206Z"
            fill="#D9D9D9"
            fillOpacity="0.85"
          />
          <ellipse
            cx="18.306"
            cy="38.6561"
            rx="3.5"
            ry="4.5"
            transform="rotate(-30.6739 18.306 38.6561)"
            fill="#BBBBBB"
          />
          <ellipse
            cx="44.306"
            cy="22.6561"
            rx="3.5"
            ry="4.5"
            transform="rotate(-30.6739 44.306 22.6561)"
            fill="#BBBBBB"
          />
        </svg>
        <div> Why Us?</div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="72"
          height="72"
          viewBox="0 0 72 72"
          fill="none"
          style={{ transform: "rotate(-162.533deg)" }}
        >
          <rect width="72" height="72" rx="36" fill="#111111" />
          <ellipse
            cx="18.2334"
            cy="36.0632"
            rx="5.5"
            ry="8.89963"
            transform="rotate(-27.492 18.2334 36.0632)"
            fill="#2D2D2D"
          />
          <ellipse
            cx="45.0084"
            cy="21.1571"
            rx="5.5"
            ry="7.78019"
            transform="rotate(-27.492 45.0084 21.1571)"
            fill="#2D2D2D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.2115 53.4206C35.4987 55.6657 52.9194 45.5116 60.195 28.9838C63.5973 34.2801 64.9934 40.8678 63.5812 47.5117C60.7384 60.8863 47.5916 69.4239 34.217 66.5811C26.5455 64.9505 20.4654 59.9298 17.2115 53.4206Z"
            fill="#D9D9D9"
            fillOpacity="0.85"
          />
          <ellipse
            cx="18.306"
            cy="38.6561"
            rx="3.5"
            ry="4.5"
            transform="rotate(-30.6739 18.306 38.6561)"
            fill="#BBBBBB"
          />
          <ellipse
            cx="44.306"
            cy="22.6561"
            rx="3.5"
            ry="4.5"
            transform="rotate(-30.6739 44.306 22.6561)"
            fill="#BBBBBB"
          />
        </svg>
      </div>
      <div className="wu-card-cont">
        <div className="wu-card">
          <img src={wu1} alt="wu1" />
          <div className="wu-card-head">Fast Delivery</div>
          <div className="wu-card-det">
            Experience the thrill of precision and speed with our digital
            solution delivery service, a seamless blend of speed and precision
            for your business with experiences and innovation it deserves
          </div>
        </div>

        <div className="wu-card">
          <img src={wu2} alt="wu1" />
          <div className="wu-card-head">Amazing Design & Development works</div>
          <div className="wu-card-det">
            "Elevate your brand with our cutting-edge design and development
            services, seamlessly blending creativity and technology to deliver a
            'wow' factor that captivates and converts."
          </div>
        </div>

        <div className="wu-card">
          <img src={wu3} alt="wu1" />
          <div className="wu-card-head">Project Updates & Tracking</div>
          <div className="wu-card-det">
            Get all the updates about your projects and track the details with
            premium 1 to 1 whatsapp service.
          </div>
        </div>
      </div>
      <div className="wu-card-cont">
        <div className="wu-card">
          <div className="wu-c-m">
            <Marquee>
              <div className="wum">Advertising</div>
              <div className="wum">Design</div>
              <div className="wum">Digital Marketing</div>
              <div className="wum">Flyers</div>
              <div className="wum">UI/UX Design</div>
              <div className="wum">Social Media Management</div>
            </Marquee>
            <Marquee>
              <div className="wum">Brochure Design</div>
              <div className="wum">Product Development</div>
              <div className="wum">Marketing Campaigns</div>
              <div className="wum">Seo Optimization</div>
              <div className="wum">Promotion</div>
            </Marquee>
          </div>
          <div className="wu-card-head">Unlimited Support Requests</div>
          <div className="wu-card-det">
            Our team has good number of experienced professionals who are ready
            to solve all your queries and support your projects.
          </div>
        </div>

        <div className="wu-card">
          <img src={wu4} alt="wu1" />
          <div className="wu-card-head">Swift Hearback on Iterations</div>
          <div className="wu-card-det">
            Swift updates, seamless workflow. Benefit from our rapid response –
            your suggestions on iterations after project review, implemented
            within 48 hours.
          </div>
        </div>

        <div className="wu-card">
          <img src={wu5} alt="wu1" />
          <div className="wu-card-head">Success Tracking</div>
          <div className="wu-card-det">
            Get your projects done with efficient success tracking methods with
            1 to 1 call connect with the team
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whyus;
