import "./styles/Reviews.css";
import wc from "../Assests/logos/Wowwie.png";
import bpc from "../Assests/logos/bpc.png";
import aerc from "../Assests/logos/aerc.png";
import resc from "../Assests/logos/resc.png";
import bst from "../Assests/logos/bst.png";

import tw from "../Assests/logos/tw.jpg";
import dhansarthi from "../Assests/logos/dhansarthi.png";
import java from "../Assests/logos/java.svg";
import dwj from "../Assests/logos/dwj.png";
import med from "../Assests/logos/med.png";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";

const Reviews = () => {
  const arr = [
    <>
      <div className="rev-c-main">
        <b className="quote">"</b>
        <br />
        Neon Shark is the style maestro for Wowwiee! Their digital magic has
        elevated our clothing brand, making trends come alive online. Wowwiee
        and Neon Shark – a perfect blend of fashion and digital brilliance!
      </div>
      <div className="rev-card-btm">
        <img src={wc} alt="med" />
        <div className="revc-btm-in">
          <div className="revc-btm-head">Rishi </div>
          <div className="revc-btm-text">Wowwiee</div>
        </div>
      </div>
    </>,
    <>
      <div className="rev-c-main">
        <b className="quote">"</b>
        <br />
        Neon Shark has transformed Resigreen's digital landscape! Their
        effective digital marketing strategies brought our real estate offerings
        to the forefront, connecting us with potential buyers seamlessly. Neon
        Shark – our trusted partner in building a vibrant online presence
      </div>
      <div className="rev-card-btm">
        <img src={resc} alt="med" />
        <div className="revc-btm-in">
          <div className="revc-btm-head">Raj</div>
          <div className="revc-btm-text">Resigreen</div>
        </div>
      </div>
    </>,

    <>
      <div className="rev-c-main">
        <b className="quote">"</b>
        <br />
        Neon Shark transformed Aero City's online presence! Their digital
        wizardry brought our real estate listings to life, reaching more dream
        home seekers. Neon Shark, the key to soaring high in the
        digital skyline!
      </div>
      <div className="rev-card-btm">
        <img src={aerc} alt="med" />
        <div className="revc-btm-in">
          <div className="revc-btm-head">Ravi</div>
          <div className="revc-btm-text">Aero city</div>
        </div>
      </div>
    </>,
    <>
      <div className="rev-c-main">
        <b className="quote">"</b>
        <br />
        Neon shark elevates cricket equipment sales.Their digital marketing
        powerss expands our reach, boosting online sales. A must for cricket
        gear success
      </div>
      <div className="rev-card-btm">
        <img src={java} alt="med" />
        <div className="revc-btm-in">
          <div className="revc-btm-head">Nishant Ranjan</div>
          <div className="revc-btm-text">JAVA SPORTS</div>
        </div>
      </div>
    </>,
    <>
      <div className="rev-c-main">
        <b className="quote">"</b>
        <br />
        Neon Shark is Remedy for medical practices.Their digiatl powerss boosts
        paitent engagement and visibility.Essential for healthCare success
      </div>
      <div className="rev-card-btm">
        <img src={med} alt="med" />
        <div className="revc-btm-in">
          <div className="revc-btm-head">Dr. Vivek Kumar</div>
          <div className="revc-btm-text">Medlife Easy</div>
        </div>
      </div>
    </>,
    <>
      <div className="rev-c-main">
        <b className="quote">"</b>
        <br />
        Neon Shark cricket event designs are unmatched.Their creativity ensures
        an unforgettable experience.The wining choice for your cricket tounament
      </div>
      <div className="rev-card-btm">
        <img src={tw} alt="med" />
        <div className="revc-btm-in">
          <div className="revc-btm-head">Divya Prabhat</div>
          <div className="revc-btm-text">T20 Gully Cricket</div>
        </div>
      </div>
    </>,
    <>
      <div className="rev-c-main">
        <b className="quote">"</b>
        <br />
        Neon Shark is digital marketting excellence.Incredibly
        effective,creative and professional, They've supercharged our online
        presence. A must-have partner for digital success
      </div>
      <div className="rev-card-btm">
        <img src={dwj} alt="med" />
        <div className="revc-btm-in">
          <div className="revc-btm-head">Rishab Didwania</div>
          <div className="revc-btm-text">Didwania Jwellers</div>
        </div>
      </div>
    </>,
    <>
      <div className="rev-c-main">
        <b className="quote">"</b>
        <br />
        Neon shark excels at lead generation.They consistently deliver
        top-quality leads.The best choice for a constant stream of potential
        customer
      </div>
      <div className="rev-card-btm">
        <img src={dhansarthi} alt="med" />
        <div className="revc-btm-in">
          <div className="revc-btm-head">Amit Mishra</div>
          <div className="revc-btm-text">Dhansarthi</div>
        </div>
      </div>
    </>,
    <>
      <div className="rev-c-main">
        <b className="quote">"</b>
        <br />
        Neon shark is the key to selling distributorships. Their marketing
        expertise attracts the right partners making expansion effortless
      </div>
      <div className="rev-card-btm">
        <img src={bst} alt="med" />
        <div className="revc-btm-in">
          <div className="revc-btm-head">Keshav Bhardawaj</div>
          <div className="revc-btm-text">Begusarai Today</div>
        </div>
      </div>
    </>,
    <>
      <div className="rev-c-main">
        <b className="quote">"</b>
        <br />
        Neon Shark is the turbo boost for Bionic Pharma! Their digital magic has
        taken our health solutions to every screen, making wellness a click
        away. Neon Shark, our digital health partner, turning
        clicks into wellness
      </div>
      <div className="rev-card-btm">
        <img src={bpc} alt="med" />
        <div className="revc-btm-in">
          <div className="revc-btm-head">Ravi</div>
          <div className="revc-btm-text">Bionic pharma</div>
        </div>
      </div>
    </>,
  ];
  return (
    <div className="rev-cont">
      <div className="ecl4"></div>
      <div className="ser-head rev-head">
        We love when our clients achieve success!
      </div>

      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
      >
        <SwiperSlide>
          <div className="rev-card">{arr[0]}</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="rev-card">{arr[1]}</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="rev-card">{arr[2]}</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="rev-card">{arr[3]}</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="rev-card">{arr[4]}</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="rev-card">{arr[5]}</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="rev-card">{arr[6]}</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="rev-card">{arr[7]}</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="rev-card">{arr[8]}</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="rev-card">{arr[9]}</div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Reviews;
