import React, { useState } from "react";
import "./styles/FooterCont.css";

const FooterCont = () => {
  const [inp, setInp] = useState("");

  // const mailtoLink = `mailto:${to}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  // window.open(mailtoLink, '_blank');

  return (
    <div className="foot-cont">
      <div className="ecl6"></div>
      <div className="foot-inner-cont">
        <div className="foot-head">NEON SHARK</div>
        <div className="foot-section-cont">
          <div className="foot-section">
            <div className="foot-inp-cont">
              <input
                type="text"
                placeholder="write to us | NEON SHARKervice@gmail.com"
                value={inp}
                onChange={(e) => setInp(e.target.value)}
              ></input>
              <div
                className="foot-inp-abs"
                onClick={() =>
                  window.open(
                    `mailto:info@neonshark.co.in?subject=Website Conatct&body=${inp}`,
                    "_blank"
                  )
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                >
                  <g clipPath="url(#clip0_185_873)">
                    <path
                      d="M15.0383 30.2218L25.3325 9.83531L3.46811 16.433L7.95996 21.8018L20.7362 13.692L10.5311 24.8659L15.0383 30.2218Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_185_873">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 15.4268) rotate(-40)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
            <div className="foot-reg-cont">
              <div className="foot-sec-head">Registered Office</div>
              <div className="foot-reg-text">Patna, Bihar</div>
            </div>
          </div>
          <div className="foot-section">
            <div
              className="foot-sec-head"
              onClick={() => {
                const section = document.querySelector("#services");

                if (section) {
                  window.scrollTo({
                    top: section.offsetTop,
                    behavior: "smooth",
                  });
                }
              }}
            >
              Services
            </div>
            <div className="foot-ser-cont">
              <div className="foot-ser-cat">
                <div className="foot-ser-text">Digital Marketing</div>
                <div className="foot-ser-text">Design</div>
                <div className="foot-ser-text">Web Development</div>
                <div className="foot-ser-text">Ads</div>
              </div>
              <div className="foot-ser-cat">
                <div className="foot-ser-text">Brochure & Flyers</div>
                <div className="foot-ser-text">Print media</div>
                <div className="foot-ser-text">Social Media Management</div>
                <div className="foot-ser-text">Brand Strategy</div>
              </div>
            </div>
          </div>
          <div className="foot-section">
            <div className="foot-sec-head">Social</div>
            <div className="foot-icon-cont">
              <a href="https://wa.me/919798990383">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12.0117 2C6.50572 2 2.02348 6.47837 2.02148 11.9844C2.02048 13.7444 2.48147 15.4626 3.35547 16.9766L2 22L7.23242 20.7637C8.69142 21.5597 10.3339 21.9775 12.0059 21.9785H12.0098C17.5148 21.9785 21.995 17.4991 21.998 11.9941C22 9.32514 20.9622 6.81573 19.0762 4.92773C17.1902 3.04073 14.6837 2.001 12.0117 2ZM12.0098 4C14.1458 4.001 16.1531 4.8338 17.6621 6.3418C19.1711 7.8518 20 9.85819 19.998 11.9922C19.996 16.3962 16.4138 19.9785 12.0078 19.9785C10.6748 19.9775 9.35441 19.6428 8.19141 19.0078L7.51758 18.6406L6.77344 18.8164L4.80469 19.2812L5.28516 17.4961L5.50195 16.6953L5.08789 15.9766C4.38989 14.7686 4.02048 13.3874 4.02148 11.9844C4.02348 7.58238 7.60677 4 12.0098 4ZM8.47656 7.375C8.30956 7.375 8.03955 7.4375 7.81055 7.6875C7.58155 7.9365 6.93555 8.53958 6.93555 9.76758C6.93555 10.9956 7.83008 12.1826 7.95508 12.3496C8.07908 12.5156 9.68175 15.1152 12.2188 16.1152C14.3267 16.9462 14.7549 16.7822 15.2129 16.7402C15.6709 16.6992 16.6904 16.1377 16.8984 15.5547C17.1064 14.9717 17.1069 14.4702 17.0449 14.3672C16.9829 14.2632 16.8164 14.2012 16.5664 14.0762C16.3174 13.9512 15.0903 13.3486 14.8613 13.2656C14.6323 13.1826 14.4648 13.1406 14.2988 13.3906C14.1328 13.6406 13.6558 14.2012 13.5098 14.3672C13.3638 14.5342 13.2188 14.5566 12.9688 14.4316C12.7188 14.3056 11.9149 14.0414 10.9609 13.1914C10.2189 12.5304 9.71827 11.7148 9.57227 11.4648C9.42727 11.2158 9.55859 11.0791 9.68359 10.9551C9.79559 10.8431 9.93164 10.6636 10.0566 10.5176C10.1806 10.3716 10.2236 10.2676 10.3066 10.1016C10.3896 9.93556 10.3472 9.78906 10.2852 9.66406C10.2232 9.53906 9.73763 8.3065 9.51562 7.8125C9.32863 7.3975 9.13112 7.38786 8.95312 7.38086C8.80813 7.37486 8.64256 7.375 8.47656 7.375Z"
                    fill="white"
                    fillOpacity="0.6"
                  />
                </svg>
              </a>
              <a href="https://instagram.com/neonshark.co.in#div">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M8 3C5.243 3 3 5.243 3 8V16C3 18.757 5.243 21 8 21H16C18.757 21 21 18.757 21 16V8C21 5.243 18.757 3 16 3H8ZM8 5H16C17.654 5 19 6.346 19 8V16C19 17.654 17.654 19 16 19H8C6.346 19 5 17.654 5 16V8C5 6.346 6.346 5 8 5ZM17 6C16.7348 6 16.4804 6.10536 16.2929 6.29289C16.1054 6.48043 16 6.73478 16 7C16 7.26522 16.1054 7.51957 16.2929 7.70711C16.4804 7.89464 16.7348 8 17 8C17.2652 8 17.5196 7.89464 17.7071 7.70711C17.8946 7.51957 18 7.26522 18 7C18 6.73478 17.8946 6.48043 17.7071 6.29289C17.5196 6.10536 17.2652 6 17 6ZM12 7C9.243 7 7 9.243 7 12C7 14.757 9.243 17 12 17C14.757 17 17 14.757 17 12C17 9.243 14.757 7 12 7ZM12 9C13.654 9 15 10.346 15 12C15 13.654 13.654 15 12 15C10.346 15 9 13.654 9 12C9 10.346 10.346 9 12 9Z"
                    fill="white"
                    fillOpacity="0.6"
                  />
                </svg>
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100085080909131&mibextid=ZbWKwL"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 2C6.489 2 2 6.489 2 12C2 17.511 6.489 22 12 22C17.511 22 22 17.511 22 12C22 6.489 17.511 2 12 2ZM12 4C16.4301 4 20 7.56988 20 12C20 16.0145 17.0653 19.313 13.2188 19.8984V14.3848H15.5469L15.9121 12.0195H13.2188V10.7266C13.2188 9.74356 13.539 8.87109 14.459 8.87109H15.9355V6.80664C15.6755 6.77164 15.1268 6.69531 14.0898 6.69531C11.9238 6.69531 10.6543 7.83931 10.6543 10.4453V12.0195H8.42773V14.3848H10.6543V19.8789C6.87029 19.2408 4 15.9702 4 12C4 7.56988 7.56988 4 12 4Z"
                    fill="white"
                    fillOpacity="0.6"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="foot-line"></div>
        <div className="foot-copy">All Rights Reserved @ NEON SHARK | 2024</div>
      </div>
    </div>
  );
};

export default FooterCont;
